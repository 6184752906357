export const dropdownMenu = () => {
	/**
	 * Append dropdowns to their nav items
	 * - assumes .has-dropdown and dropdown class (i.e. ".who-we-are" or ".insights-news") *   are first and second classes of .menu-item
	 * - those classes are added in the WordPress menu's page
	 */
	// let width = $(window).width();
	// if (width > 1200) {
		$('#nav-primary .menu-item.has-dropdown').each(function () {
			let menuItemClasses = $(this).attr('class').split(/\s+/);
			let targetClass = menuItemClasses[1];

			$(this).append($('.nav-dropdown.' + targetClass));
		});
	// }

};
