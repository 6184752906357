import { accordionJS } from '../components/accordion';
import { dropdownMenu } from '../components/dropdown-menu';
import { mobileMenu } from '../components/mobile-menu';
import {basicSplide} from '../lib/splide';
import AOS from 'aos';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

export default {
	init() {
		// JavaScript to be fired on all pages

		// Animate on Scroll Library https://michalsnik.github.io/aos/
		AOS.init();

		// Component

		accordionJS();
		dropdownMenu();
		mobileMenu();

		// Lib

		// Splide https://splidejs.com/
		basicSplide();

		$(document).ready(function() {
			// When the select field value changes
			$('.cta-select').on('change', function() {
				// Add a class to the select field
				$(this).addClass('select-active');
			});
		});

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired

	},
};
