export const mobileMenu = () => {
	const menuBtn = document.getElementById('menu-btn');
	const mobileMenu = document.getElementById('mobile-menu');

	if (menuBtn && mobileMenu) {
		menuBtn.addEventListener('click', () => {
			menuBtn.classList.toggle('is-active');
			mobileMenu.classList.toggle('mobile-menu--open');
		});

		const openSubMenu = mobileMenu.querySelectorAll('.open-sub-menu');
		const subMenu = mobileMenu.querySelectorAll('.sub-menu');
		openSubMenu.forEach((element, index) => {
			element.addEventListener('click', (e) => {
				e.preventDefault();
				$(subMenu[index]).slideToggle(200);
			})
		});
	}
};
